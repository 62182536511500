import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ReportGroup} from '../../classes/report-group';
import {ReportService} from '../../services/report.service';
import {Report} from '../../classes/report';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';

@Component({
    selector: 'eaglei-report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['../reports.scss', './report-list.component.scss'],
})
export class ReportListComponent implements OnInit {
    public reportGroups: ReportGroup[] = [];
    public unpinnedReports: Report[] = [];
    public pinnedReports: Report[] = [];
    public allReports: Report[] = [];

    public filteredGroupId: number;
    public sorted: boolean;
    public hasSearchText: boolean;
    private searchText: string = '';

    constructor(private router: Router, private reportService: ReportService, private popup: MatSnackBar) {
        this.getReports();
    }

    ngOnInit() {}

    private getReports(): void {
        HttpInterceptorService.clearInterceptor('reportList');
        HttpInterceptorService.pendingRequests['reportList'] = this.reportService.getAllReports().subscribe((res) => {
            HttpInterceptorService.deleteFromInterceptor('reportList');
            this.reportGroups = res;
            this.allReports = this.reportGroups.reduce((prev, cur) => prev.concat(cur.dataProviders), []);
            this.allReports.forEach((report) => {
                if (report.pinned) {
                    this.pinnedReports.push(report);
                } else {
                    this.unpinnedReports.push(report);
                }
            });

            this.sortReports(true);
        });
    }

    // Method to generate the URL for each report
    getReportLink(report: any): string {
        return `/app/reports/${report.serviceurl}`;
    }

    // Navigation Methods
    /**
     * Navigates to the selected report
     * @param report the report that is being navigated to.
     */
    public goToReport(report: Report) {
        const endpoint = report.serviceurl;
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['app/reports', `${endpoint}`]);
    }

    // Pinning methods
    /**
     * Pins a report for quick access and landing page.
     * @param report the report to be pinned.
     * @param event The event being fired from the DOM
     */
    public pinReport(report: Report, event: MouseEvent): void {
        event.stopPropagation();
        this.reportService.pinReport(report.id).subscribe(
            () => {
                report.pinned = true;
                const index = this.unpinnedReports.findIndex((r) => r.id === report.id);
                if (index !== -1) {
                    this.unpinnedReports.splice(index, 1);
                    this.pinnedReports.push(report);
                    this.applyFilters();
                }

                this.popup.open('Report Pinned', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            },
            (error: any) => {
                const message = error.userMessage || 'Error pinning report';
                this.popup.open(message, 'Okay', {duration: 5000, panelClass: 'dialog-failure'});
            }
        );
    }

    /**
     * Removes a report from quick access and removes it from the landing page.
     * @param report The report to be unpinned.
     * @param event The event being fired from the DOM
     */
    public unpinReport(report: Report, event: MouseEvent): void {
        event.stopPropagation();
        this.reportService.unpinReport(report.id).subscribe(
            () => {
                report.pinned = false;
                const pinnedIndex = this.pinnedReports.findIndex((pr) => pr.id === report.id);
                if (pinnedIndex !== -1) {
                    this.pinnedReports.splice(pinnedIndex, 1);
                    this.unpinnedReports.push(report);
                    this.applyFilters();
                }

                this.popup.open('Report Unpinned', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            },
            (error: any) => {
                const message = error.userMessage || 'Error unpinning report';
                this.popup.open(message, 'Okay', {duration: 5000, panelClass: 'dialog-failure'});
            }
        );
    }

    // Filter methods
    /**
     * Searches the Report titles for any that contain the search string
     * @param text The text to be found in the report titles
     */
    public searchReports(text: string): void {
        this.hasSearchText = !!text;
        this.applyFilters(text);
    }

    /**
     * Filters the unpinned groups to just the selected group
     * @param groupId The Id of the group to be filtered to
     */
    public filterToGroup(groupId: number): void {
        this.filteredGroupId = this.filteredGroupId === groupId ? undefined : groupId;
        this.applyFilters();
    }

    /**
     * Sorts the report, when the sort flag is true it will sort alphabetically, otherwise it uses the group ordering
     * @param bypassCheck if true, the sort flag will not be updated
     */
    public sortReports(bypassCheck: boolean = false): void {
        if (!bypassCheck) {
            this.sorted = !this.sorted;
        }

        this.applyFilters();
    }

    private applyFilters(text?: string): void {
        this.searchText = text === undefined ? this.searchText : text;

        this.unpinnedReports = this.allReports.filter((report) => {
            const textCheck = report.displayName.toLowerCase().includes(this.searchText.toLowerCase());
            const groupCheck = this.filteredGroupId === undefined ? true : report.dataProviderGroupId === this.filteredGroupId;
            return !report.pinned && textCheck && groupCheck;
        });

        if (this.sorted) {
            this.unpinnedReports.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
        } else {
            this.unpinnedReports.sort((a, b) => {
                const aGroup = this.reportGroups.find((g) => g.id === a.dataProviderGroupId);
                const bGroup = this.reportGroups.find((g) => g.id === b.dataProviderGroupId);

                if (aGroup.ordering > bGroup.ordering) {
                    return 1;
                } else if (aGroup.ordering < bGroup.ordering) {
                    return -1;
                } else {
                    return a.ordering > b.ordering ? 1 : -1;
                }
            });
        }
    }
}
